/* customFonts.css */
@font-face {
    font-family: 'Slackey-Regular';
    src:     
    url('../../public/Slackey-Regular.ttf')     
          format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'MuktaMalar-Regular';
    src:     
    url('../../public/MuktaMalar-Regular.ttf')     
          format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  